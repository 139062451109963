import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  ICustomerGroup,
  empytCustomerGroup,
} from '../../lib/interfaces/customer-group.interfaces';
import {
  IGroupRole,
  empytGroupRole,
} from '../../lib/interfaces/group-role.interfaces';
import apiIntance from '../../utils/apiUtils';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Link,
  Sidebar,
  Spinner,
  Tag,
  Text,
  Title,
  Toggle,
  Tooltip,
  useToast,
} from '@nimbus-ds/components';
import {
  DataList,
  EmptyMessage,
  FormField,
  Layout,
  Page,
} from '@nimbus-ds/patterns';
import {
  CogIcon,
  DiscountCircleIcon,
  EcosystemIcon,
  EditIcon,
  ExclamationCircleIcon,
  MoneyIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  StarIcon,
  TagIcon,
  TrashIcon,
  UploadIcon,
  UserIcon,
} from '@nimbus-ds/icons';
import { InputColor, LabelHelp } from '../../components';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../utils/formatUtils';
import { navigateHeader } from '@tiendanube/nexo';
import nexo from '../../nexoClient';

const CustomersGroupPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loadingData, setLoadingData] = useState(true);
  const [myGroups, setMyGroups] = useState<ICustomerGroup[]>([]);
  const [newGroup, setNewGroup] = useState<ICustomerGroup>({
    ...empytCustomerGroup,
  });
  const [myGroupRole, setMyGroupRole] = useState<IGroupRole | null>({
    ...empytGroupRole,
  });
  const [formGroupOpen, setFormGroupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const navigate = useNavigate();
  const handleGoToRelations = (id: string) =>
    navigate(`/customers-group/${id}/relations`);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('General.back')}` });
    handleGetGroupRole();
    handleGetData();
    setLoadingData(false);
  }, []);

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const updatedData: any = { ...myGroupRole };
    let currentField = updatedData;
    const fieldPath = name.split('.');

    fieldPath.forEach((field, index) => {
      if (index === fieldPath.length - 1) {
        if (field in currentField) {
          currentField[field] =
            type === 'checkbox'
              ? (event.target as HTMLInputElement).checked
              : value;
        }
      } else {
        currentField = currentField[field];
      }
    });

    setMyGroupRole(updatedData);
  };

  const handleChangeNewGroup = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const updatedData: any = { ...newGroup };
    let currentField = updatedData;
    const fieldPath = name.split('.');

    fieldPath.forEach((field, index) => {
      if (index === fieldPath.length - 1) {
        if (field in currentField) {
          currentField[field] =
            type === 'checkbox'
              ? (event.target as HTMLInputElement).checked
              : value;
        }
      } else {
        currentField = currentField[field];
      }
    });

    setNewGroup(updatedData);
  };

  const handleGetGroupRole = async () => {
    try {
      const result = await apiIntance.get(`/group-roles`);
      if (result.data !== null) {
        setMyGroupRole({ ...empytGroupRole, ...result.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetData = async () => {
    try {
      const result = await apiIntance.get(`/customer-groups/details`);
      if (result.data !== null) {
        setMyGroups(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenModalGroupNew = () => {
    setNewGroup({ ...empytCustomerGroup });
    setFormGroupOpen(true);
  };

  const handleCloseFormGroup = () => {
    setNewGroup({ ...empytCustomerGroup });
    setFormGroupOpen(false);
  };

  const handleSaveGroup = async () => {
    try {
      if (newGroup._id) {
        const update_id = newGroup._id;
        const saveData = { ...newGroup };
        delete saveData._id;
        delete saveData.createdAt;
        delete saveData.customers_count;
        delete saveData.updatedAt;
        await apiIntance.put(`/customer-groups/${update_id}`, saveData);
        addToast({
          id: 'save-group' + new Date().toISOString(),
          type: 'success',
          text: t(
            'app.config.customers-group.page.modal_form_group.save_update_success',
          ),
          position: 4,
        });
      } else {
        await apiIntance.post(`/customer-groups`, newGroup);
        addToast({
          id: 'save-group' + new Date().toISOString(),
          type: 'success',
          text: t(
            'app.config.customers-group.page.modal_form_group.save_create_success',
          ),
          position: 4,
        });
      }
      handleGetData();
      handleCloseFormGroup();
    } catch (error) {
      addToast({
        id: 'save-group' + new Date().toISOString(),
        type: 'danger',
        text: t('app.config.customers-group.page.modal_form_group.save_error'),
        position: 4,
      });
    }
    return false;
  };

  const handleChangeGroupState = async (
    id: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    const state = checked ? 'on' : 'off';
    const status_t = checked ? 'active' : 'inactive';
    try {
      await apiIntance.put(`/customer-groups/${id}/status/${state}`);
      const updatedGroups = myGroups.map((g) => {
        if (g._id === id) {
          return {
            ...g,
            active: checked,
          };
        } else {
          return g;
        }
      });
      setMyGroups(updatedGroups);
      addToast({
        id: 'change-group-status' + new Date().toISOString(),
        type: 'success',
        text: t(
          `app.config.customers-group.page.cards.group_list.success_change_${status_t}`,
        ),
        position: 4,
      });
    } catch (error) {
      event.target.checked = !checked;
      addToast({
        id: 'change-group-status' + new Date().toISOString(),
        type: 'danger',
        text: t(
          'app.config.customers-group.page.cards.group_list.error_change_active',
        ),
        position: 4,
      });
    }
    return false;
  };

  const handleEditGroup = (group: ICustomerGroup) => {
    setNewGroup({ ...group });
    setFormGroupOpen(true);
    return false;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
      handleUpload(event.target.files[0]);
    }
  };

  const handleUpload = async (file: File) => {
    setIsUploading(true); // Mostra o loader
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await apiIntance.post(
        '/customer-groups/relations/import',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      const { message, imported, errors } = response.data;

      // Mensagem de sucesso
      addToast({
        id: 'upload-success' + new Date().toISOString(),
        type: 'success',
        text: `${imported} registros importados com sucesso. ${
          errors.length > 0 ? 'Alguns erros ocorreram.' : ''
        }`,
        position: 4,
      });

      // Exibe os erros em um modal ou console
      if (errors.length > 0) {
        console.error('Erros encontrados:', errors);
        errors.forEach((error: any) => {
          addToast({
            id: 'upload-error-' + new Date().toISOString(),
            type: 'danger',
            text: error.message || 'Erro desconhecido.',
            position: 4,
          });
        });
      }

      // Atualiza os dados após a importação
      handleGetData();
    } catch (error) {
      console.error(error);
      addToast({
        id: 'upload-error' + new Date().toISOString(),
        type: 'danger',
        text: 'Erro ao importar a planilha. Verifique o arquivo e tente novamente.',
        position: 4,
      });
    } finally {
      setIsUploading(false); // Oculta o loader
    }
  };

  const handleSetDefaultGroup = async (group: ICustomerGroup) => {
    try {
      await apiIntance.put(`/customer-groups/${group._id}/set-default`);
      const updatedGroups = myGroups.map((g) => ({
        ...g,
        default_role: g._id === group._id,
      }));
      setMyGroups(updatedGroups);
      addToast({
        id: 'set-group-default' + new Date().toISOString(),
        type: 'success',
        text: t(
          'app.config.customers-group.page.cards.group_list.success_set_default',
          { group: group.name },
        ),
        position: 4,
      });
    } catch (error) {
      addToast({
        id: 'set-group-default' + new Date().toISOString(),
        type: 'danger',
        text: t(
          'app.config.customers-group.page.cards.group_list.error_set_default',
        ),
        position: 4,
      });
    }
    return false;
  };

  const handleRemoveGroup = async (group: ICustomerGroup) => {
    try {
      if (
        window.confirm(
          `${t(
            'app.config.customers-group.page.cards.group_list.confirm_remove_action',
          )}`,
        )
      ) {
        await apiIntance.delete(`/customer-groups/${group._id}`);

        await handleGetData();
        addToast({
          id: 'set-group-default' + new Date().toISOString(),
          type: 'success',
          text: t(
            'app.config.customers-group.page.cards.group_list.success_delete',
          ),
          position: 4,
        });
      }
    } catch (error) {
      addToast({
        id: 'set-group-default' + new Date().toISOString(),
        type: 'danger',
        text: t(
          'app.config.customers-group.page.cards.group_list.error_delete',
        ),
        position: 4,
      });
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      setLoadingData(true);

      const payload = { ...myGroupRole };

      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;

      await apiIntance.post(`/group-roles`, payload);
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'success',
        text: t('app.config.customers-group.page.alerts.toast_form_success'),
        position: 4,
      });
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: t('app.config.customers-group.page.alerts.toast_form_error'),
        position: 4,
      });
    }
    setLoadingData(false);
    return false;
  };

  const GroupsDataList: React.FC = () => {
    if (myGroups.length) {
      return (
        <Card padding="none">
          <Card.Header>
            <Box
              paddingTop="4"
              paddingX="4"
              display="flex"
              justifyContent="space-between"
            >
              <Title as="h3">
                {t('app.config.customers-group.page.cards.group_list.title')}
              </Title>
              <Link
                as="button"
                appearance="primary"
                onClick={() => document.getElementById('fileInput')?.click()}
                disabled={isUploading}
              >
                {isUploading ? (
                  <Spinner color="currentColor" size="small" />
                ) : (
                  <Icon
                    source={<UploadIcon size="small" />}
                    color="currentColor"
                  />
                )}
                Importar planilha
              </Link>
            </Box>
            <Box display="none">
              <Input
                id="fileInput"
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
              />
            </Box>
          </Card.Header>
          <Card.Body padding="none">
            <DataList>
              {myGroups.map((group, index) => {
                return (
                  <Box
                    key={index}
                    draggable="true"
                    display="flex"
                    flexDirection="column"
                    padding="4"
                    gap="1"
                    width="100%"
                    borderStyle="solid"
                    borderWidth="none"
                    borderTopWidth="1"
                    borderColor="neutral-surfaceHighlight"
                    boxSizing="border-box"
                  >
                    <Box display="grid" width="100%" gap="4">
                      <Box display="grid" width="100%" gap="1">
                        <Box
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          justifyContent="space-between"
                          gap="2"
                        >
                          <Text as="p" fontWeight="bold">
                            {group.name}
                          </Text>
                          <Toggle
                            name={`active-${index}`}
                            checked={group.active}
                            label=""
                            onChange={(event) =>
                              handleChangeGroupState(group._id!, event)
                            }
                          />
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="1"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Icon source={<TagIcon size="small" />} />
                          <Text as="p">{group.display_name}</Text>
                          {group.default_role ? (
                            <Tooltip
                              content={t(
                                'app.config.customers-group.page.cards.group_list.default_role_tooltip_true',
                              )}
                            >
                              <Icon
                                source={<StarIcon />}
                                color="warning-interactive"
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </Box>

                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="1"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Icon source={<UserIcon size="small" />} />
                          <Text as="p">
                            {group.customers_count}{' '}
                            {t(
                              'app.config.customers-group.page.cards.group_list.tag_customers_group_count',
                            )}
                          </Text>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-between"
                        gap="2"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          gap="4"
                        >
                          <Box display="grid" gap="1" justifyContent="center">
                            <Text
                              as="span"
                              fontSize="caption"
                              lineHeight="caption"
                            >
                              1° pedido
                            </Text>
                            <Tooltip
                              content={t(
                                'app.config.customers-group.page.cards.group_list.tag_minimum_first_order',
                              )}
                            >
                              <Tag appearance="primary">
                                <Text color="primary-textLow">
                                  {formatMoney(group.minimum_first_order)}
                                </Text>
                              </Tag>
                            </Tooltip>
                          </Box>
                          <Box display="grid" gap="1">
                            <Text
                              as="span"
                              fontSize="caption"
                              lineHeight="caption"
                            >
                              Valor min
                            </Text>
                            <Tooltip
                              content={t(
                                'app.config.customers-group.page.cards.group_list.tag_minimum_order',
                              )}
                            >
                              <Tag appearance="primary">
                                <Text color="primary-textLow">
                                  {formatMoney(group.minimum_order)}
                                </Text>
                              </Tag>
                            </Tooltip>
                          </Box>
                          <Box display="grid" gap="1">
                            <Text
                              as="span"
                              fontSize="caption"
                              lineHeight="caption"
                            >
                              Qtd. min.
                            </Text>
                            <Tooltip
                              content={t(
                                'app.config.customers-group.page.cards.group_list.tag_minimum_qty',
                              )}
                            >
                              <Tag appearance="primary">
                                <Text color="primary-textLow">
                                  {group.minimum_items_cart}un
                                </Text>
                              </Tag>
                            </Tooltip>
                          </Box>
                          {/* <Box display="grid" gap="1">
                                <Text as="span" fontSize="caption" lineHeight="caption">Desconto pedidos</Text>
                                <Tooltip content={t('app.config.customers-group.page.cards.group_list.tag_discount_shop')}>
                                    <Tag appearance="success">
                                        <Text color="primary-textLow">{group.discount_shop.toFixed(2)}%</Text>
                                    </Tag>
                                </Tooltip>
                            </Box> */}
                        </Box>

                        <Box display="flex" flexDirection="row" gap="1">
                          {!group.default_role ? (
                            <Tooltip
                              content={t(
                                'app.config.customers-group.page.cards.group_list.default_role_tooltip_false',
                              )}
                            >
                              <IconButton
                                size="36px"
                                source={<StarIcon size="small" />}
                                onClick={() => handleSetDefaultGroup(group)}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                          <Tooltip
                            content={t(
                              'app.config.customers-group.page.cards.group_list.cta_relations',
                            )}
                          >
                            <IconButton
                              size="36px"
                              source={<EcosystemIcon size="small" />}
                              onClick={() => handleGoToRelations(group._id!)}
                            />
                          </Tooltip>
                          <IconButton
                            size="36px"
                            source={<EditIcon size="small" />}
                            onClick={() => handleEditGroup(group)}
                          />
                          <IconButton
                            size="36px"
                            source={<TrashIcon size="small" />}
                            onClick={() => handleRemoveGroup(group)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </DataList>
          </Card.Body>
        </Card>
      );
    } else {
      return (
        <Box marginX="auto" marginY="16">
          <EmptyMessage
            title={`${t('app.config.customers-group.page.empty_title')}`}
            text={`${t('app.config.customers-group.page.empty_message')}`}
            icon={<CogIcon size={32} />}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('app.config.customers-group.page.title')}
          buttonStack={
            <Box display="flex" flexDirection="row" gap="6">
              <Toggle
                name="active"
                disabled={loadingData}
                checked={myGroupRole?.active}
                onChange={handleChange}
                label={`${t(`General.active`)}`}
              />
              <Button appearance="primary" onClick={handleOpenModalGroupNew}>
                <Icon source={<PlusCircleIcon />} color="currentColor" />
                {t('app.config.customers-group.page.ctas.add_field_text')}
              </Button>
            </Box>
          }
        />
        <Page.Body>
          {myGroupRole && (
            <Layout columns="2 - symmetric">
              <Layout.Section>
                <Box display="grid" gap="4">
                  <Card>
                    <Card.Header
                      title={`${t(
                        'app.config.customers-group.page.cards.roles.title',
                      )}`}
                    />
                    <Card.Body>
                      <Box display="grid" gap="4">
                        <FormField>
                          <Checkbox
                            name="hide_products_visitor"
                            checked={myGroupRole.hide_products_visitor}
                            disabled={loadingData}
                            onChange={handleChange}
                            label={`${t(
                              'app.config.customers-group.page.cards.roles.input_hide_products_visitor_label',
                            )}`}
                          />
                        </FormField>
                        <FormField>
                          <Checkbox
                            name="hide_products_price_visitor"
                            checked={myGroupRole.hide_products_price_visitor}
                            disabled={loadingData}
                            onChange={handleChange}
                            label={`${t(
                              'app.config.customers-group.page.cards.roles.input_hide_products_price_visitor_label',
                            )}`}
                          />
                        </FormField>

                        {myGroupRole.hide_products_price_visitor ? (
                          <>
                            <FormField helpIcon={ExclamationCircleIcon}>
                              <Box display="flex" gap="1" alignItems="center">
                                <Checkbox
                                  name="show_register_login_cta"
                                  checked={myGroupRole.show_register_login_cta}
                                  disabled={loadingData}
                                  onChange={handleChange}
                                  label={`${t(
                                    'app.config.customers-group.page.cards.roles.input_show_register_login_cta_label',
                                  )}`}
                                />
                                <Tooltip
                                  content={`${t(
                                    'app.config.customers-group.page.cards.roles.input_show_register_login_cta_help_text',
                                  )}`}
                                >
                                  <Icon
                                    source={<QuestionCircleIcon size={12} />}
                                    color="primary-textHigh"
                                  />
                                </Tooltip>
                              </Box>
                            </FormField>
                            {myGroupRole.show_register_login_cta ? (
                              <>
                                <FormField
                                  label={`${t(
                                    'app.config.customers-group.page.cards.roles.input_register_cta_text_label',
                                  )}`}
                                >
                                  <Input
                                    name="register_cta_text"
                                    disabled={loadingData}
                                    onChange={handleChange}
                                    value={myGroupRole.register_cta_text}
                                  />
                                </FormField>
                                <FormField
                                  label={`${t(
                                    'app.config.customers-group.page.cards.roles.input_login_cta_text_label',
                                  )}`}
                                >
                                  <Input
                                    name="login_cta_text"
                                    disabled={loadingData}
                                    onChange={handleChange}
                                    value={myGroupRole.login_cta_text}
                                  />
                                </FormField>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        <FormField
                          label={`${t(
                            'app.config.customers-group.page.cards.roles.input_page_visitor_redirect_label',
                          )}`}
                        >
                          <Input
                            name="page_visitor_redirect"
                            disabled={loadingData}
                            onChange={handleChange}
                            value={myGroupRole.page_visitor_redirect}
                          />
                        </FormField>

                        {myGroupRole.hide_products_visitor ||
                        myGroupRole.hide_products_price_visitor ? (
                          <Box display="grid" gap="4">
                            <Box>
                              <Text
                                fontWeight="bold"
                                color="danger-interactive"
                              >
                                Atenção:
                              </Text>
                              <Text>
                                {t(
                                  'app.config.customers-group.page.cards.roles.helper_html_hide_info_text',
                                )}{' '}
                                <Link
                                  onClick={() => {
                                    nexo.dispatch({
                                      type: 'app/navigate/goToOldAdmin',
                                      payload: {
                                        pathToOldAdmin:
                                          '/preferences/advanced/#name-container',
                                      },
                                    });
                                  }}
                                  appearance="primary"
                                >
                                  {t(
                                    'app.config.customers-group.page.cards.roles.helper_html_hide_info_text_link',
                                  )}
                                </Link>
                              </Text>
                              <Text fontWeight="medium">
                                {t(
                                  'app.config.customers-group.page.cards.roles.helper_html_hide_info_text_breadcrumb',
                                )}
                              </Text>
                            </Box>
                            <Box
                              backgroundColor="neutral-surfaceHighlight"
                              padding="2"
                              borderRadius="2"
                            >
                              <Text
                                color="danger-textLow"
                                fontWeight="bold"
                                wordBreak="break-all"
                              >
                                {`<style>.huapps-b2b-hide-prices,.huapps-b2b-hide-products{display:none!important;opacity:0!important;visibility:0!important;overflow:hidden!important;width:0!important;height:0!important}</style><script>!function(){function t(){document.querySelectorAll('[data-store*="product-form-"]').length&&document.querySelectorAll('[data-store*="product-form-"]').forEach(function(t){t.classList.add("huapps-b2b-hide-prices")}),document.querySelectorAll('[data-store*="product-item-price-"]').length&&document.querySelectorAll('[data-store*="product-item-price-"]').forEach(function(t){t.classList.add("huapps-b2b-hide-prices")}),document.querySelectorAll('[data-store*="product-price-"]').length&&document.querySelectorAll('[data-store*="product-price-"]').forEach(function(t){t.classList.add("huapps-b2b-hide-prices")}),document.querySelectorAll('[data-component="product-list-item.add-to-cart"]').length&&document.querySelectorAll('[data-component="product-list-item.add-to-cart"]').forEach(function(t){t.classList.add("huapps-b2b-hide-prices")}),document.querySelectorAll('[data-component="product.add-to-cart"]').length&&document.querySelectorAll('[data-component="product.add-to-cart"]').forEach(function(t){t.classList.add("huapps-b2b-hide-prices")})}"loading"===document.readyState?document.addEventListener("DOMContentLoaded",t):t()}()</script>`}
                              </Text>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Box display="flex" justifyContent="space-between">
                        <Title as="h3">
                          {t(
                            'app.config.customers-group.page.cards.discount.title',
                          )}
                        </Title>
                      </Box>
                    </Card.Header>
                    <Card.Body>
                      <Box display="grid" gap="4">
                        <Checkbox
                          name="discount_apply"
                          checked={myGroupRole.discount_apply}
                          disabled={loadingData}
                          onChange={handleChange}
                          label={`${t(
                            'app.config.customers-group.page.cards.discount.discount_apply_label',
                          )}`}
                        />

                        {myGroupRole.discount_apply ? (
                          <FormField
                            label={t(
                              'app.config.customers-group.page.cards.discount.discount_default_message_label',
                            )}
                          >
                            <Input
                              name="discount_default_message"
                              disabled={loadingData}
                              onChange={handleChange}
                              value={myGroupRole.discount_default_message}
                            />
                          </FormField>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Header
                      title={`${t(
                        'app.config.customers-group.page.cards.messages.title',
                      )}`}
                    />
                    <Card.Body>
                      <Box display="grid" gap="4">
                        <FormField
                          label={
                            <LabelHelp
                              label={t(
                                'app.config.customers-group.page.cards.messages.input_message_qty_min_cart_label',
                              )}
                              helpText={t(
                                'app.config.customers-group.page.cards.messages.input_message_qty_min_cart_label_help',
                              )}
                            />
                          }
                          helpIcon={ExclamationCircleIcon}
                          showHelpText={true}
                          helpText={`${t(
                            'app.config.customers-group.page.cards.messages.input_message_qty_min_cart_help_text',
                          )}`}
                        >
                          <Input
                            name="message_qty_min_cart"
                            disabled={loadingData}
                            onChange={handleChange}
                            value={myGroupRole.message_qty_min_cart}
                          />
                        </FormField>
                        <FormField
                          label={
                            <LabelHelp
                              label={t(
                                'app.config.customers-group.page.cards.messages.input_message_value_min_cart_label',
                              )}
                              helpText={t(
                                'app.config.customers-group.page.cards.messages.input_message_value_min_cart_label_help',
                              )}
                            />
                          }
                          helpIcon={ExclamationCircleIcon}
                          showHelpText={true}
                          helpText={`${t(
                            'app.config.customers-group.page.cards.messages.input_message_value_min_cart_help_text',
                          )}`}
                        >
                          <Input
                            name="message_value_min_cart"
                            disabled={loadingData}
                            onChange={handleChange}
                            value={myGroupRole.message_value_min_cart}
                          />
                        </FormField>
                      </Box>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Header
                      title={`${t(
                        'app.config.customers-group.page.cards.colors.title',
                      )}`}
                    />
                    <Card.Body>
                      <Box display="grid" gap="6">
                        <Box
                          display="flex"
                          gap="4"
                          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                        >
                          <InputColor
                            disabled={loadingData}
                            required={true}
                            value={myGroupRole.message_cart_background_color}
                            name="message_cart_background_color"
                            label={`${t(
                              'app.config.customers-group.page.cards.colors.input_message_cart_background_color_label',
                            )}`}
                            labelHelpText={`${t(
                              'app.config.customers-group.page.cards.colors.input_message_cart_background_color_label_help',
                            )}`}
                            onChange={handleChange}
                          />
                          <InputColor
                            disabled={loadingData}
                            required={true}
                            value={myGroupRole.message_cart_text_color}
                            name="message_cart_text_color"
                            label={`${t(
                              'app.config.customers-group.page.cards.colors.input_message_cart_text_color_label',
                            )}`}
                            labelHelpText={`${t(
                              'app.config.customers-group.page.cards.colors.input_message_cart_text_color_label_help',
                            )}`}
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>
                    </Card.Body>
                  </Card>

                  <Button
                    disabled={loadingData}
                    appearance="primary"
                    onClick={handleSubmit}
                  >
                    {loadingData ? (
                      <Spinner color="currentColor" size="small" />
                    ) : (
                      ''
                    )}
                    {t('General.save')}
                  </Button>
                </Box>
              </Layout.Section>
              <Layout.Section>
                <Box position="sticky" top="10px">
                  <GroupsDataList />
                </Box>
              </Layout.Section>
            </Layout>
          )}
        </Page.Body>
      </Page>

      <Sidebar
        padding="base"
        open={formGroupOpen}
        onRemove={handleCloseFormGroup}
      >
        <Sidebar.Header
          title={`${
            newGroup._id
              ? t('app.config.customers-group.page.modal_form_group.title_edit')
              : t('app.config.customers-group.page.modal_form_group.title_new')
          }`}
        />
        <Sidebar.Body>
          <Box
            alignItems="center"
            borderColor="neutral-interactive"
            boxSizing="border-box"
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
            width="100%"
          >
            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_default_role_help_text',
              )}`}
            >
              <Checkbox
                name="default_role"
                checked={newGroup.default_role}
                onChange={handleChangeNewGroup}
                tabIndex={1}
                label={`${t(
                  'app.config.customers-group.page.modal_form_group.input_group_default_role_label',
                )}`}
              />
            </FormField>

            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_name_label',
              )}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_name_help_text',
              )}`}
            >
              <Input
                tabIndex={2}
                onChange={handleChangeNewGroup}
                value={newGroup.name}
                name="name"
                required={true}
              />
            </FormField>

            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_display_name_label',
              )}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_display_name_help_text',
              )}`}
            >
              <Input
                tabIndex={3}
                onChange={handleChangeNewGroup}
                value={newGroup.display_name}
                name="display_name"
                required={true}
              />
            </FormField>

            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_icon_label',
              )}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_icon_help_text',
              )}`}
            >
              <Input
                tabIndex={4}
                onChange={handleChangeNewGroup}
                value={newGroup.icon}
                name="icon"
                required={false}
              />
            </FormField>

            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_minimun_items_cart_label',
              )}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_minimun_items_cart_help_text',
              )}`}
            >
              <Input
                tabIndex={5}
                type="number"
                min={0}
                step={1}
                value={newGroup.minimum_items_cart}
                name="minimum_items_cart"
                required={false}
                onChange={handleChangeNewGroup}
                append={<Text>un</Text>}
                appendPosition="end"
              />
            </FormField>

            <FormField
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_min_first_order_label',
              )}
            >
              <Input
                tabIndex={6}
                type="number"
                min={0}
                step={0.01}
                value={newGroup.minimum_first_order}
                name="minimum_first_order"
                required={false}
                onChange={handleChangeNewGroup}
                append={<Icon source={<MoneyIcon />} />}
                appendPosition="start"
              />
            </FormField>

            <FormField
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_min_order_label',
              )}
            >
              <Input
                tabIndex={7}
                type="number"
                min={0}
                step={0.01}
                value={newGroup.minimum_order}
                name="minimum_order"
                required={false}
                onChange={handleChangeNewGroup}
                append={<Icon source={<MoneyIcon />} />}
                appendPosition="start"
              />
            </FormField>

            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_discount_shop_label',
              )}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_discount_shop_help_text',
              )}`}
            >
              <Input
                tabIndex={8}
                type="number"
                min={0}
                step={0.01}
                onChange={handleChangeNewGroup}
                value={newGroup.discount_shop}
                name="discount_shop"
                required={false}
                append={<Icon source={<DiscountCircleIcon />} />}
                appendPosition="end"
              />
            </FormField>

            <FormField
              showHelpText={true}
              helpIcon={ExclamationCircleIcon}
              label={t(
                'app.config.customers-group.page.modal_form_group.input_group_ignore_products_label',
              )}
              helpText={`${t(
                'app.config.customers-group.page.modal_form_group.input_group_ignore_products_help_text',
              )}`}
            >
              <Input
                tabIndex={9}
                onChange={handleChangeNewGroup}
                value={newGroup.ignore_products}
                name="ignore_products"
                required={false}
              />
            </FormField>
          </Box>
        </Sidebar.Body>
        <Sidebar.Footer>
          <Box
            alignItems="center"
            borderColor="neutral-interactive"
            boxSizing="border-box"
            display="flex"
            height="100%"
            justifyContent="space-between"
            width="100%"
          >
            <Button appearance="neutral" onClick={handleCloseFormGroup}>
              {t('General.cancel')}
            </Button>
            <Button
              appearance="primary"
              onClick={handleSaveGroup}
              tabIndex={10}
            >
              {t('General.save')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>
    </>
  );
};

export default CustomersGroupPage;
